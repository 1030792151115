<template>
  <div>
    <iframe
      v-if="yid"
      id="ytplayer"
      type="text/html"
      width="100%"
      style="height: 60vh"
      :src="'https://www.youtube.com/embed/' + yid + '?autoplay=1'"
      frameborder="0"
      allowfullscreen
    />

    <iframe
      v-else-if="vkid"
      :src="'https://vk.com/video_ext.php?' + vkid"
      width="100%"
      style="height: 60vh"
      frameborder="0"
      allowfullscreen="1"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "knowledgeBase-youtube",
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    yid() {
      return this.youtube_parser(this.url);
    },
    vkid() {
      return this.vk_parser(this.url);
    },
  },
  methods: {
    youtube_parser(url) {
      if (!url) return false;
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
    vk_parser(url) {
      if (!url || (!url.includes("vk.com") && !url.includes("vk.ru")))
        return false;
      let oid;
      let id;
      if (url.includes("video_ext")) {
        oid = url.match(/oid=(-?\d+)/)[1];
        id = url.match(/id=(\d+)/)[1];
      } else if (url.includes("video") || url.includes("clip")) {
        const pars = url.match(/[video|clip](-?[0-9]+)_([0 -9]+)/);
        if (pars.length >= 3) {
          oid = pars[1];
          id = pars[2];
        }
      }
      return oid && id ? `oid=${oid}&id=${id}` : false;
    },
  },
};
</script>
